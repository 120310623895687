import React, { FunctionComponent } from 'react';
import AdminLayout from '../layouts/admin-index';
import CarForm from '../components/CarForm';
import CarList from '../components/CarList';

const Index: FunctionComponent = () => {
  return (
    <AdminLayout>
      <h1 className="title">Voertuigen</h1>
      <div className="cars-container">
        <div className="card">
          <div className="card-header">
            <div className="card-header-title">Voeg een voertuig toe:</div>
          </div>
          <div className="card-content">
            <div className="content">
              <div className="car-item">
                <CarForm car={null} />
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <div className="card-header-title">Voertuigen:</div>
          </div>
          <div className="card-content">
            <div className="content">
              <CarList />
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Index;
