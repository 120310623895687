import React, { FunctionComponent, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { Car, createOrUpdateCar } from '../firebaseCars';

interface CarFormProps {
  car: Car | null;
}

const CarForm: FunctionComponent<CarFormProps> = ({ car }) => {
  const [formBusy, setFormBusy] = useState(false);
  const [formError, setFormError] = useState('');
  const isAddForm = !car;

  const initialValues = () => {
    if (car === null) {
      return {
        uid: '',
        licencePlate: '',
      };
    }
    return car;
  };

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object().shape({
      licencePlate: Yup.string().required(),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log(values, resetForm);
      createOrUpdateCar(values)
        .then(() => {
          setFormBusy(false);
          setFormError('');
          if (isAddForm) {
            resetForm();
          }
        })
        .catch((error: Error) => {
          console.error(error);
          setFormBusy(false);
          setFormError(error.message);
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} onChange={() => setFormError('')}>
      <input type="hidden" name="uid" />
      <div className="field is-horizontal">
        <div className="field-body">
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                name="licencePlate"
                placeholder="Nummerplaat"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.licencePlate}
              />
            </div>
            {formik.touched.licencePlate && formik.errors.licencePlate ? (
              <p className="help is-danger">{formik.errors.licencePlate}</p>
            ) : null}
          </div>
          <div className="field is-grouped is-grouped-right">
            <div className="control flex-grow">
              <button
                disabled={formBusy}
                type="submit"
                className={classNames({
                  'button is-success is-fullwidth': true,
                  'is-loading': formBusy,
                })}
              >
                'Opslaan'
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="field">{formError ? <p className="has-text-danger">{formError}</p> : null}</div>
    </form>
  );
};

export default CarForm;
