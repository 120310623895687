import React, { FunctionComponent, useEffect, useState } from 'react';
import classNames from 'classnames';
import CarForm from './CarForm';
import { Car, getCarList } from '../firebaseCars';

const CarList: FunctionComponent = () => {
  const [carList, setCarList] = useState<Array<Car>>([]);

  useEffect(() => {
    return getCarList(setCarList);
  }, []);

  return (
    <div
      className={classNames({
        'cars-container--list': true,
      })}
    >
      {carList.map((car: Car) => {
        return (
          <div className="car-item" key={car.uid}>
            <CarForm car={car} />
          </div>
        );
      })}
    </div>
  );
};

export default CarList;
